












































































import {Component, Prop, Vue} from "vue-property-decorator";
import axios from 'axios'
import * as XLSX from 'xlsx'
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";

@Component({
    components: {},
    computed: {
        locale() {
            return this.$i18n.locale;
        }
    }
})
export default class SpfImportFromFileModal  extends AlterationI18nMixin {
    @Prop({
        required: false,
        default: null
    })
    private curAbp!: any;
    @Prop({
        required: false,
        default: null
    })
    private curGu!: any;
    @Prop({
        required: false,
        default: null
    })
    private curRegion!: any;
    @Prop({
        required: false,
        default: null
    })
    private curMonth!: any;

    @Prop({
        required: false,
        default: false
    })
    private show!: any;
    @Prop({
        required: false,
        default: 1
    })
    private tableType!: any;

    @Prop({
        required: false,
        default: 0
    })
    private requestId!: any;

    @Prop({
        required: false,
        default: 2023
    })
    private curYear!: any;

    @Prop({
        required: false,
        default: true
    })
    private calcField0!: any;
    private curentRequest: any = {};
    private tableTypeLocal: any = 1
    private selectedFile: any = null;
    private tableData: any[] = []
    private importedData: any[] = []
    private calcFields:string[] = [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ]
    private tableFields: any[] = [
        { key: 'abp', label: this.$t('modules.budget_execution_alteration.abp') },
        { key: 'gu', label: this.$t('modules.budget_execution_alteration.ga') },
        {
            key: 'prg',
            label: this.$t('modules.budget_execution_alteration.program')
        },
        {
            key: 'ppr',
            label: this.$t('modules.budget_execution_alteration.ppr')
        },
        {
            key: 'spf',
            label: this.$t('modules.budget_execution_alteration.spf')
        },
        { key: 'total', label: this.$t('modules.budget_execution_alteration.totals') },
        {
            key: 'month0',
            label: this.$t('modules.budget_execution_alteration.january')
        },
        {
            key: 'month1',
            label: this.$t('modules.budget_execution_alteration.february')
        },
        {
            key: 'month2',
            label: this.$t('modules.budget_execution_alteration.march')
        },
        {
            key: 'month3',
            label: this.$t('modules.budget_execution_alteration.april')
        },
        {
            key: 'month4',
            label: this.$t('modules.budget_execution_alteration.may')
        },
        {
            key: 'month5',
            label: this.$t('modules.budget_execution_alteration.june')
        },
        {
            key: 'month6',
            label: this.$t('modules.budget_execution_alteration.july')
        },
        {
            key: 'month7',
            label: this.$t('modules.budget_execution_alteration.august')
        },
        {
            key: 'month8',
            label: this.$t('modules.budget_execution_alteration.september')
        },
        {
            key: 'month9',
            label: this.$t('modules.budget_execution_alteration.october')
        },
        {
            key: 'month10',
            label: this.$t('modules.budget_execution_alteration.november')
        },
        {
            key: 'month11',
            label: this.$t('modules.budget_execution_alteration.december')
        },
    ];
    private showModal = false;
    private created() {
        const that = this;
        this.$watch('showModal', () => {
            if (!this.showModal) this.resetModal()
        })
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        })
        this.$watch('tableType', function () {
            that.tableTypeLocal = that.tableType
        });
        this.$watch('requestId', async function () {
            await this.updateRequest()
        });
        this.$watch('locale', this.localeChange);
    }
    private mounted() {}
    private resetModal() {
        this.tableData = [];
        this.importedData = []
        for (const key of Object.keys(this.importErrors)) {
            this.importErrors[key] = false
        }
        this.importErrors.duplicates = []
    }
    private importErrors:any = {
        incorrectRows: false,
        negativeValue: false,
        duplicationError: false,
        duplicates: []
    }
    private importFiles() {
        const resObj = {
            fact: this.tableData,
            plan: this.tableData,
            error: this.importErrors
        }
        if (this.tableTypeLocal === 1) {
            this.$emit('importTableObligations', resObj)
        } else {
            this.$emit('importTablePayments', resObj)
        }
        this.showModal = false;
        this.resetModal()
    }
    private async loadSpfDict(spfs: any[]) {
        const req = {
            abp: this.curAbp.abp,
            year: this.curYear,
            spf_list: spfs
        }
        let dict:any = {}
        await axios.post('/api-py/dict-ebk-spf-map', req).then(response => {
            dict = response.data
        })
        return dict
    }
    private parseFloatZero(input:any) {
        const rowN = input? input.toString().replace(',', '.'): 0
        const floatValue = parseFloat(rowN);
        return Number.isNaN(floatValue) ? 0 : floatValue;
    }
    private async updateRequest() {
        if (!this.requestId) {
            this.curentRequest = {
                abp: this.curAbp.abp,
                region: this.curRegion
            }
            return;
        }
        await axios.get(`/api-py/import-file-request-id/${this.requestId}`).then(response => {
            this.curentRequest = response.data
        }).catch(error => {
            console.log(error)
            this.curentRequest = null
        })
    }
    private async getTalbeData() {
        const isRowEmpty = (row: any[]) => row.every(cell => cell === '' || cell === null || isNaN(cell));
        const spfs = []
        const preData = [];
        await this.updateRequest()
        for (const row of this.importedData) {
            if (isRowEmpty(row.slice(0, 4))) continue;
            try {
                const region = (this.curentRequest && this.curentRequest.region)? this.curentRequest.region: row[0]
                const apbPrgPpr:string = row[1].toString()
                const abp = (this.curentRequest && this.curentRequest.abp)? this.curentRequest.abp: parseInt(apbPrgPpr.slice(0, 3), 10)
                const prg = parseInt(apbPrgPpr.slice(3, 6), 10)
                let ppr:any = parseInt(apbPrgPpr.slice(6), 10)
                if (!ppr) ppr = null;
                const spf = parseInt(row[2].toString())
                const gu = row[3].toString()
                let total = 0
                if (gu !== this.curGu.code.toString()) {
                    // this.importErrors.incorrectRows = true
                    continue;
                }
                const spfObj = {
                    region: region,
                    abp: abp,
                    abp_name: '',
                    gu: gu,
                    gu_name: '',
                    prg: prg,
                    prg_name: '',
                    prg_name_ru: '',
                    prg_name_kk: '',
                    prg_name_en: '',
                    prgName: '',
                    ppr: ppr,
                    ppr_name: '',
                    ppr_name_ru: '',
                    ppr_name_kk: '',
                    ppr_name_en: '',
                    pprName: '',
                    spf: spf,
                    name_ru: '',
                    name_kk: '',
                    name_en: '',
                    plan: total,
                    plan_type: 1,
                    total: 0,
                    month: this.curMonth,
                    gr: null
                }
                preData.push(spfObj)
                spfs.push({
                    spf: spf,
                    prg: prg,
                    ppr: ppr
                })
                for (let i = 5; i < 17; i++) {
                    if (i-4 <this.curMonth) {
                        if (!this.calcField0) {
                            this.$set(spfObj, this.calcFields[i - 5 + 1], 0);
                        }
                        this.$set(spfObj, 'month' + (i-5), 0);
                        this.$set(spfObj, 'month_' + (i-5), 0);
                    } else {
                        const value = this.parseFloatZero(row[i])
                        if (value < 0) {
                            this.importErrors.negativeValue = true
                        }
                        if (!this.calcField0) {
                            this.$set(spfObj, this.calcFields[i - 5 + 1], value);
                        }
                        this.$set(spfObj, 'month' + (i-5), value);
                        this.$set(spfObj, 'month_' + (i-5), value);
                        total += value;
                    }
                }
                total = parseFloat(total.toFixed(1))
                spfObj.total = total;
                spfObj.plan = total
            } catch (e) {
                console.log(e)
            }
        }
        if (preData.length === 0) {
            this.makeToast('warning', this.setText('import_table'), this.setText('gu_not_match'))
            return
        }
        const spfDict:any = await this.loadSpfDict(spfs)
        if (Object.keys(spfDict).length === 0) {
            this.makeToast('warning', this.setText('import_table'), this.setText('prg_ppr_not_match_abp'))
            return
        }
        const locale_i18 = 'name_' + this.$i18n.locale
        const newTable: any[] = [];
        const duplicates:any[] = [];
        for (let i = 0; i < preData.length; i++) {
            const new_item = Object.assign({}, preData[i])
            const spfKey = `${new_item.spf}_${new_item.prg}_${new_item.ppr}`
            if (!spfDict[spfKey]) {
                this.importErrors.incorrectRows = true
                continue;
            }
            new_item.prg_name = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_' + locale_i18]
            new_item.prgName = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_' + locale_i18]
            new_item.prg_name_ru = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_name_ru']
            new_item.prg_name_kk = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_name_kk']
            new_item.prg_name_en = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_name_ru']
            new_item.ppr_name = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_' + locale_i18]
            new_item.pprName = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_' + locale_i18]
            new_item.ppr_name_ru = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_name_ru']
            new_item.ppr_name_kk = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_name_kk']
            new_item.ppr_name_en = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_name_ru']
            new_item.name_ru = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['name_ru']
            new_item.name_kk = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['name_kk']
            new_item.name_en = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['name_en']
            new_item.gr = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['gr']
            new_item.ppr = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr']
            newTable.push(new_item)
            if (duplicates.includes(spfKey)) {
                this.importErrors.duplicationError = true;
                this.importErrors.duplicates.push(new_item)
            } else {
                duplicates.push(spfKey)
            }
        }
        this.tableData = newTable
        if (this.tableData.length === 0) {
            this.makeToast('warning', this.setText('import_table'), this.setText('gu_not_match'))
        }
    }
    private async handleFileInput (event:any) {
        this.tableData = []
        this.importedData = []
        this.selectedFile = null
        // Access the selected file from the event
        this.selectedFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: "binary"});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            this.importedData = data.slice(1)
            await this.getTalbeData()
        };
        reader.readAsBinaryString(this.selectedFile);

    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    private localeChange() {
        this.tableFields[0].label = this.$t('modules.budget_execution_alteration.abp');
        this.tableFields[1].label = this.$t('modules.budget_execution_alteration.ga');
        this.tableFields[2].label = this.$t('modules.budget_execution_alteration.program');
        this.tableFields[3].label = this.$t('modules.budget_execution_alteration.ppr');
        this.tableFields[4].label = this.$t('modules.budget_execution_alteration.spf');
        this.tableFields[5].label = this.$t('modules.budget_execution_alteration.totals');
        this.tableFields[6].label = this.$t('modules.budget_execution_alteration.january');
        this.tableFields[7].label = this.$t('modules.budget_execution_alteration.february');
        this.tableFields[8].label = this.$t('modules.budget_execution_alteration.march');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.april');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.may');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.june');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.july');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.august');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.september');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.october');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.november');
        this.tableFields[9].label = this.$t('modules.budget_execution_alteration.december');
    }

}
